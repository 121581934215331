.root {
	display: flex;
	flex-direction: column;
	min-height: 419px;
	padding: var(--hdsplus-spacing-08);
}

.badgesBase {
	list-style-type: none;
	padding: 0;
	display: flex; 
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-05);
}

.badges {
	composes: badgesBase;
	margin: 0 0 var(--hdsplus-spacing-05) 0;
}

.badgesWithText {
	composes: badgesBase;
	margin: 0 0 var(--hdsplus-spacing-07) 0;
}

.heading {
	display: block;
	font-weight: 700;
	margin-bottom: var(--hdsplus-spacing-07);
}

.speakers {
	list-style-type: none;
	margin: auto 0 0 0;
	padding: 0;
	display: grid;
	gap: var(--hdsplus-spacing-03);
}

.speaker {
	display: flex;
	align-items: center;
	gap: var(--hdsplus-spacing-05);
}

.speakerImage {
	border-radius: 9999px;
	overflow: hidden;
	width: var(--hdsplus-spacing-10);
	height: var(--hdsplus-spacing-10);

	& img {
		max-width: 100%;
		height: auto;
	}
}

.speakerName {
	display: block;
	font-weight: 700;
}

.speakerCompany {
	display: block;
}